import { render, staticRenderFns } from "./ProposalScreen.vue?vue&type=template&id=e80fb3de&scoped=true&"
import script from "./ProposalScreen.js?vue&type=script&lang=js&"
export * from "./ProposalScreen.js?vue&type=script&lang=js&"
import style0 from "./ProposalScreen.scss?vue&type=style&index=0&id=e80fb3de&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e80fb3de",
  null
  
)

export default component.exports